import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { LegalsPageTemplate } from '../components/LegalsPageTemplate';
import SEO from '../components/seo';

export default () => {
  const [activeTab, setActiveTab] = useState({
    desktop: 'argentina',
    mobile: null,
  });
  const { countries } = useStaticQuery(graphql`
    query {
      strapi {
        countries(sort: "name") {
          name
          codename
          IsoAlpha3
          unicodeFlagChar
          termsAndConditions
        }
      }
    }
  `).strapi;
  return (
    <>
      <SEO title="Términos y condiciones" />
      <LegalsPageTemplate
        title="Términos y condiciones"
        countries={countries}
        activeTab={activeTab}
        onSetActiveTab={setActiveTab}
        countryFieldSelector={country => country.termsAndConditions}
      />
    </>
  );
};
