import React from 'react';
import { Layout } from '../layout/Layout';
import { Container as BaseContainer } from '../components/Container';
import styled from 'styled-components';
import { H3, CellText } from '@increasecard/typed-components';
import { MEDIUM } from '../breakpoints';

const UpperWrapper = styled.div`
  padding-top: 96px;
`;
const LowerWrapper = styled.div`
  display: none;
  background: ${({ theme }) => theme.colorsNew.gray.white};
  color: ${({ theme }) => theme.colorsNew.gray.black};
  overflow: auto;

  @media (min-width: ${MEDIUM}) {
    display: block;
  }
`;

const UpperContainer = styled(BaseContainer)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

const LowerContainer = styled(BaseContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 707px;
  padding: 80px 0;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 60px;
  line-height: 73px;
  letter-spacing: -0.02em;
  margin-bottom: 8px;
`;

const TitleWrapper = styled.div`
  margin-bottom: 80px;
`;

const TabBar = styled.ul`
  display: flex;
  width: 100%;
  flex-direction: column-reverse;
  align-items: flex-end;

  > li {
    width: 100%;
  }

  @media (min-width: ${MEDIUM}) {
    justify-content: center;
    flex-direction: row-reverse;
    > li {
      width: auto;
    }

    > li:not(:first-child) {
      margin-right: 3px;
    }
  }
`;
const DesktopTab = styled.button`
  display: none;
  color: ${({ theme }) => theme.colorsNew.gray.black};
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.012em;
  justify-content: center;
  align-items: center;
  background: ${({ active, theme }) =>
    active ? theme.colorsNew.gray.white : theme.colorsNew.gray.grayLight};

  @media (min-width: ${MEDIUM}) {
    display: flex;
    border-radius: 6.5px 6.5px 0px 0px;
    width: ${({ active }) => (active ? '132px' : '112px')};
    height: ${({ active }) => (active ? '40px' : '34px')};
  }
`;
const MobileTab = styled.button`
  color: ${({ theme }) => theme.colorsNew.gray.black};
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.012em;
  display: flex;
  width: 100%;
  height: 64px;
  justify-content: center;
  align-items: center;
  background: ${({ active, theme }) =>
    active ? theme.colorsNew.gray.white : theme.colorsNew.gray.grayLight};
  box-shadow: ${({ theme }) => theme.shadows.rest};

  @media (min-width: ${MEDIUM}) {
    display: none;
  }
`;

const FullTextContainer = styled(CellText).attrs({ as: 'div' })`
  max-width: 100%;

  code {
    display: inline-block;
    white-space: normal;
  }
  h1,
  h2,
  h3 {
    font-weight: bold;
  }

  h1 {
    ${({ theme }) => theme.typography.H3}
    margin-bottom: 32px;
  }

  p {
    margin-bottom: 8px;
  }

  a {
    text-decoration: underline;
    color: ${({ theme }) => theme.colorsNew.primaryAction.dark};
  }
`;

const DesktopTextContainer = styled(FullTextContainer)``;

const MobileTextContainer = styled(FullTextContainer)`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  background: ${({ theme }) => theme.colorsNew.gray.white};
  color: ${({ theme }) => theme.colorsNew.gray.black};
  padding: 24px;
  @media (min-width: ${MEDIUM}) {
    display: none;
  }
`;

export function LegalsPageTemplate({
  title,
  subtitle,
  activeTab,
  onSetActiveTab,
  countries,
  countryFieldSelector,
}) {
  const reversedCountries = countries.slice(0).reverse();
  const activeCountry = {
    desktop: countries.find(({ codename }) => codename === activeTab.desktop),
    mobile: countries.find(({ codename }) => codename === activeTab.mobile),
  };
  return (
    <Layout variant="increase-dark">
      <UpperWrapper>
        <UpperContainer>
          <TitleWrapper>
            <Title>{title}</Title>
            <H3 weight="normal" style={{ textAlign: 'right' }}>
              {subtitle}
            </H3>
          </TitleWrapper>
        </UpperContainer>
        <TabBar>
          {reversedCountries.map(({ codename, IsoAlpha3, unicodeFlagChar }) => (
            <li key={codename}>
              <DesktopTab
                active={activeTab.desktop === codename}
                onClick={() =>
                  activeTab.desktop === codename
                    ? onSetActiveTab({ mobile: null, desktop: null })
                    : onSetActiveTab({ mobile: codename, desktop: codename })
                }
              >
                {unicodeFlagChar} {IsoAlpha3}
              </DesktopTab>
              <MobileTab
                active={activeTab.mobile === codename}
                onClick={() =>
                  activeTab.mobile === codename
                    ? onSetActiveTab({ mobile: null, desktop: null })
                    : onSetActiveTab({ mobile: codename, desktop: codename })
                }
              >
                {unicodeFlagChar} {IsoAlpha3}
              </MobileTab>
              <MobileTextContainer
                visible={activeTab.mobile === codename}
                dangerouslySetInnerHTML={{
                  __html:
                    activeCountry.mobile &&
                    countryFieldSelector(activeCountry.mobile),
                }}
              />
            </li>
          ))}
        </TabBar>
      </UpperWrapper>
      <LowerWrapper>
        <LowerContainer>
          <DesktopTextContainer
            dangerouslySetInnerHTML={{
              __html:
                activeCountry.desktop &&
                countryFieldSelector(activeCountry.desktop),
            }}
          ></DesktopTextContainer>
        </LowerContainer>
      </LowerWrapper>
    </Layout>
  );
}
